import { guquanSale } from "@/api";
export default {
  data() {
    return {
      chosePayItem: 0,
      num: "",
      password: "",
      accountType: "666",
      showPicker: true
    };
  },
  methods: {
    chosePay(value) {
      this.chosePayItem = value;
    },
    onConfirm() {
      if (this.num < 1) {
        this.$toast("请输入数量");
        return;
      }
      if (this.password.length < 3) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      guquanSale({
        num: this.num,
        pwd: this.password
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
        this.show = false;
        this.password = "";
        this.num = "";
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};